<template>
  <!-- 图文解读新增编辑页面 -->
  <div>
    <Header
      back="返回"
      title="云学院"
      index="首页"
      titleOne="官网管理"
      titleTwo="云学院"
      titleThree="图文解读"
      beforeTitle="新增/编辑"
    />
    <div class="content">
      <div>
        <span>封面</span>
        <div class="flex_l" style="margin-top: 15px;">
          <Imgcover ref="imgcover" />
        </div>
      </div>
      <div>
        <el-form
          style="margin-top: 20px;"
          :rules="formRules"
          label-position="top"
          ref="form"
          :model="imgParams"
        >
          <el-form-item label="标题名称" prop="title">
            <el-input v-model="imgParams.title"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <!-- 富文本编辑框 -->
      <div class="about">
        <span class="policy_text" style="font-size: 16px;color: #000;">图文内容</span>

        <editor :content.sync="imgParams.content"></editor>
      </div>

      <div style="width: 100%;text-align: center;">
        <el-button type="primary" :loading="btnLoading" class="sure" @click="sure()">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Gqburl from '../../components/global.vue'
import Imgcover from '../../components/upload/uploadCover.vue'
import Editor from '../../components/tinymce.vue'
export default {
  components: {
    Imgcover,
    Editor,
  },
  data () {
    return {
      btnLoading: false,//提交按钮动画
      infoCover: '',//回显封面
      childUrl: '',
      gqbpath: Gqburl.userSite,
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      // form: {},
      imgParams: {
        id: '',
        type: 'article',
      },
      formRules: {
        title: [
          { required: true, message: '内容不能为空', trigger: 'blur' },
        ],
      },
    }
  },
  created () {
    if (this.$route.query.id) {
      this.imgParams.id = this.$route.query.id
      this.videoView()
    }
  },
  methods: {
    videoView () {
      this.axios.get('/api/website/view_article', {
        params: {
          id: this.$route.query.id,
          type: 'article'
        }
      }).then((res) => {
        this.imgParams = res.data
        this.infoCover = res.data.cover_url
      })
    },
    sure () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.$route.query.id) {
            this.imgParams.cover_url = this.gqbpath + this.infoCover
          } else {
            this.imgParams.cover_url = this.gqbpath + this.$refs.imgcover.fileCover.url
          }
          this.btnLoading = true
          this.axios.post('/api/website/store_article', { ...this.imgParams, type: 'article' }).then((res) => {
            this.btnLoading = false
            this.$message.success(res.message)
            this.$router.go(-1)
          }).catch(() => {
            this.btnLoading = false
          })
        } else {
          this.$message.warning('请完善信息')
        }
      })
    },
  }
}
</script>

<style scoped>
.sure {
  margin: 50px auto;
}

.add_video {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.add_video i {
  display: block;
  font-size: 50px;
}

.add_video span {
  display: block;
  line-height: 16px;
  margin-top: 15px;
  font-size: 16px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  color: #0f38ff;
}

.add_video p {
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  line-height: 14px;
  color: #909399;
}

.card_box {
  display: flex;
}

.flex_l {
  position: relative;
}

.flex_r {
  position: relative;
  margin-left: 20px;
}

div/deep/.el-upload--picture-card {
  width: 370px !important;
  height: 234px !important;
}

/* div/deep/.el-upload--picture-card{
    position: relative;
  } */

div/deep/.el-upload-list--picture-card .el-upload-list__item {
  width: 370px;
  height: 234px;
  position: absolute;
  top: 0;
  z-index: 9;
}

div/deep/.el-upload-list--picture-card .el-upload-list__item-actions {
  top: 0;
}
div/deep/.ql-container {
  min-height: 300px;
}
</style>